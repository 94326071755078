<template>
    <article v-if="prodotto">

        <section class="prodottoInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="prodotto.translations && prodotto.translations[0]?.body" :body="prodotto.translations[0]?.body" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="prodotto.media && prodotto.media.length == 1" :image="prodotto.media[0]" :size="'small'" />
                        <ModuloMedia v-else-if="prodotto.media && prodotto.media.length > 1" :media="prodotto.media" :size="'small'" />

                        <!-- CONTATTI -->
                        <SezioneShare />

                        <!-- TARIFFE / FILE -->
                        <SezioneAccordionProdotto :prodotto="prodotto" />

                        <!-- PAGINE COLLEGATE -->
                        <ListaPagineCollegate v-if="prodotto.pagine_collegate && prodotto.pagine_collegate.length > 0" :pagine="prodotto.pagine_collegate" />

                        <!-- ADERENTI -->
                        <SezioneAccordionAderenti :prodotto="prodotto" />

                        <OggettoAcquistaProdotto :prodotto="prodotto" />
                    </div>
                    <aside>

                        <!-- BANNER -->
                        <ListaSideBanner v-if="prodotto.banner && prodotto.banner.length > 0" :banner="prodotto.banner" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MODULI -->
        <template v-for="modulo in prodotto.moduli">
            <ModuloCarousel v-if="modulo.item && modulo.item.__typename == 'modulo_carousel'" :modulo="modulo.item" />
            <ModuloCarouselSocial v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel_social'" :modulo="modulo.item" />
            <ModuloLista v-else-if="modulo.item && modulo.item.__typename == 'modulo_lista'" :modulo="modulo.item" />
            <ModuloAllegati v-else-if="modulo.item && modulo.item.__typename == 'modulo_allegati'" :modulo="modulo.item" />

            <ModuloDontMiss v-else-if="modulo.item && modulo.item.__typename == 'modulo_dontmiss'" :modulo="modulo.item" />
            <ModuloGrid v-else-if="modulo.item && modulo.item.__typename == 'modulo_grid'" :modulo="modulo.item" />
            <ModuloBigCard v-else-if="modulo.item && modulo.item.__typename == 'modulo_bigcard'" :modulo="modulo.item" />
            <ModuloCard v-else-if="modulo.item && modulo.item.__typename == 'modulo_card'" :modulo="modulo.item" />

            <ModuloTesto v-else-if="modulo.item && modulo.item.__typename == 'modulo_text'" :modulo="modulo.item" />
            <ModuloImmagine v-else-if="modulo.item && modulo.item.__typename == 'modulo_image'" :image="{ directus_files_id: modulo.item.immagine }" />
            <ModuloVideo v-else-if="modulo.item && modulo.item.__typename == 'modulo_video'" :modulo="modulo.item" />

            <ModuloOutdoorActive v-else-if="modulo.item && modulo.item.__typename == 'modulo_outdooractive'" :modulo="modulo.item" />
            <ModuloCalendario v-else-if="modulo.item && modulo.item.__typename == 'modulo_calendario'" :modulo="modulo.item" />
            <ModuloCerca v-else-if="modulo.item && modulo.item.__typename == 'modulo_cerca'" :modulo="modulo.item" />
            <MappaPagine v-else-if="modulo.item && modulo.item.__typename == 'modulo_map'" :modulo="modulo.item" />

            <ModuloNewsletterForm v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'form'" :modulo="modulo.item" />
            <ModuloNewsletterGrid v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'griglia'" :modulo="modulo.item" />
            <ModuloExtra v-else-if="modulo.item && modulo.item.__typename == 'modulo_extra'" :modulo="modulo.item" />
            <ModuloPress v-else-if="modulo.item && modulo.item.__typename == 'modulo_press'" :modulo="modulo.item" />
        </template>

    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { dizionario } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ prodotto: any, routes: any }>()

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_title ? `${props.prodotto.translations[0].og_title} | ${t('prodotti')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_title ? `${props.prodotto.translations[0].og_title} | ${t('prodotti')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_title ? `${props.prodotto.translations[0].og_title} | ${t('prodotti')} | Turismo Torino e Provincia` : null,

    description: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_description ? props.prodotto.translations[0].og_description : null,
    ogDescription: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_description ? props.prodotto.translations[0].og_description : null,
    twitterDescription: () => props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_description ? props.prodotto.translations[0].og_description : null,

    ogImage: () => props.prodotto && props.prodotto.cover && props.prodotto.cover.height && props.prodotto.cover.width ? `${directusUrl}assets/${props.prodotto.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.prodotto && props.prodotto.cover && props.prodotto.cover.height && props.prodotto.cover.width ? `${directusUrl}assets/${props.prodotto.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    name: dizionario.titoloVisita[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: dizionario.titoloPianifica[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkPianifica[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: dizionario.titoloProdotti[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkPianifica[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkProdotti[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 4,
                    name: props.prodotto.translations[0].titolo,
                    item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                }
            ]
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_title ? props.prodotto.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.prodotto && props.prodotto.cover && props.prodotto.cover.height && props.prodotto.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.prodotto.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.prodotto.cover.height * 1600 / props.prodotto.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.prodotto.cover.title
            } : undefined,
            datePublished: props.prodotto && props.prodotto.date_created ? new Date(props.prodotto.date_created).toJSON() : undefined,
            dateModified: props.prodotto && props.prodotto.date_updated ? new Date(props.prodotto.date_updated).toJSON() : undefined,
            description: props.prodotto && props.prodotto.translations && props.prodotto.translations[0]?.og_description ? props.prodotto.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .prodottoInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .prodottoInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>