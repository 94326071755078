<template>
    <div class="buttonCont">
        <NuxtLinkLocale v-if="['carte', 'abbonamenti_athena', 'bundle'].includes(prodotto.tipologia_acquistabile) && disponibilita && disponibilita.disponibile === true" :to="{ name: 'acquista-tipo-id', params: { tipo: prodotto.tipologia_acquistabile, id: idProdotto } }" :custom="true">
            <template v-slot="{ navigate }">
                <NButton size="medium" type="primary" round @click="navigate">
                    <template #icon>
                        <NIcon>
                            <IconShop />
                        </NIcon>
                    </template>
                    {{ $t(prodotto.tipologia_cta) }}
                </NButton>
            </template>
        </NuxtLinkLocale>
        <template v-if="['carte', 'abbonamenti_athena', 'bundle'].includes(prodotto.tipologia_acquistabile) && disponibilita && disponibilita.disponibile === false && disponibilita.inizio_vendita">
            <NButton size="medium" round :disabled="true">{{ $t('disponibile_dal') }} {{ $dataSenzaOrario(disponibilita.inizio_vendita) }}</NButton>
        </template>
        <template v-else-if="['abbonamenti_athena', 'bundle'].includes(prodotto.tipologia_acquistabile) && disponibilita && disponibilita.disponibile === false && disponibilita.reason">
            <NButton size="medium" round :disabled="true">{{ $t(disponibilita.reason) }}</NButton>
        </template>
        <template v-else-if="['carte', 'abbonamenti_athena', 'bundle'].includes(prodotto.tipologia_acquistabile) && disponibilita && disponibilita.disponibile === false">
            <NButton size="medium" round :disabled="true">{{ $t('non_disponibile') }}</NButton>
        </template>
        <a v-else-if="prodotto.tipologia_acquistabile == 'link'" :href="`https://${prodotto.link.replace('https://', '').replace('http://', '')}`" target="_blank">
            <NButton size="medium" type="primary" round>{{ $t(prodotto.tipologia_cta) }}</NButton>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui';
const { checkDisponibilitaProdotto } = useProdotti()
const props = defineProps<{ prodotto: any }>()

const idProdotto = computed(() => {
    if (props.prodotto.tipologia_acquistabile == 'carte') return props.prodotto.carta.id
    else if (props.prodotto.tipologia_acquistabile == 'abbonamenti_athena') return props.prodotto.abbonamento_athena.id
    else if (props.prodotto.tipologia_acquistabile == 'bundle') return props.prodotto.bundle.id
    else return null
})

const { data: disponibilita } = useAsyncData(`disponibilita-${props.prodotto.tipologia_acquistabile}-${props.prodotto.id}`, () => checkDisponibilitaProdotto(props.prodotto.tipologia_acquistabile, idProdotto.value))
</script>

<style lang="scss" scoped>
.buttonCont {
    &:deep(.n-button.n-button--disabled) {
        background-color: black;
        color: white;
    }
}
</style>